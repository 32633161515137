import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';

import Page from '../components/Page';
import CellWithPopover from '../components/Popover';
import StatusChip from '../components/StatusChip';
import { apiClient } from '../apiClient';

interface Schedule {
  uid: string;
  created_ts: string;
  org_uid: string;
  market: string;
  last_modified_ts: string;
  tso: string;
  latest_version: number;
  status: string;
  sender_id?: string;
  production: { ts: number[]; amount: number[] } | { ts: never[]; amount: string[] };
  consumption: { ts: number[]; amount: number[] } | { ts: never[]; amount: string[] };
  internal: { ts: number[]; amount: number[] } | { ts: never[]; amount: string[] };
  external: { ts: number[]; amount: number[] } | { ts: never[]; amount: string[] };
}

const paginationModel = { page: 0, pageSize: 5 };

const columns: GridColDef[] = [
  {
    field: 'uid',
    headerName: 'UID',
    flex: 0,
    minWidth: 50,
    renderCell: (params) => (
      <Link to={`/schedule-management/${params.value}`} style={{ textDecoration: 'none', color: '#1976d2' }}>
        <CellWithPopover value={params.value} displayValue={`...${params.value.slice(-8)}`} />
      </Link>
    )
  },
  {
    field: 'tso',
    headerName: 'TSO',
    flex: 1,
    width: 50,
    renderCell: (params) => <CellWithPopover value={params.value} displayValue={params.value} />,
  },
  { field: 'sender_id', headerName: 'BRP', flex: 1, minWidth: 150 },
  { field: 'latest_version', headerName: 'Version', flex: 1, minWidth: 30, maxWidth: 70 },
  {
    field: 'production',
    headerName: 'Production',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => renderSparkline(params.value as { amount: number[]; ts: number[] }, 'production'),
  },
  {
    field: 'consumption',
    headerName: 'Consumption',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => renderSparkline(params.value as { amount: number[]; ts: number[] }, 'consumption'),
  },
  {
    field: 'internal',
    headerName: 'Internal',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => renderSparkline(params.value as { amount: number[]; ts: number[] }, 'internal'),
  },
  {
    field: 'external',
    headerName: 'External',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => renderSparkline(params.value as { amount: number[]; ts: number[] }, 'external'),
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <StatusChip status={params.value as 'accepted' | 'submitted' | 'pending' | 'partially_accepted' | 'rejected'} />,
  },
];

const renderSparkline = (
  data: { amount: number[]; ts: number[] },
  type: 'production' | 'consumption' | 'internal' | 'external'
) => {
  if (!data || data.amount.length <= 1) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Chip label="N/A" color="default" size="small" />
      </Box>
    );
  }

  const colorMap = {
    production: 'hsl(120, 61%, 50%)', // Blue
    consumption: 'hsl(30, 100%, 50%)',  // Red
    internal: 'hsl(200, 70%, 50%)',   // Green
    external: 'hsl(280, 60%, 50%)',    // Orange
  };

  return (
    <SparkLineChart
      data={data.amount.map(Number)}
      width={100}
      height={32}
      plotType="line"
      showHighlight
      showTooltip
      colors={[colorMap[type]]}
      xAxis={{
        scaleType: 'band',
        data: data.ts,
        valueFormatter: (value) => `Quarter: ${value}`, // Format x-axis values
      }}
    />
  );
};

const ScheduleManagementPage = () => {
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    apiClient('schedules')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data: Schedule[]) => {
        const schedulesWithTypes = data.map((schedule) => {
          return apiClient(`schedules/${schedule.uid}/versions/${schedule.latest_version}`)
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then((versionData) => {
              try {
                const series = versionData?.spec?.series || [];
                const senderId = versionData?.spec?.sender_id || 'N/A';

                const typeMap: { [key: string]: { amount: number[]; ts: number[] } } = {
                  production: { amount: [], ts: [] },
                  consumption: { amount: [], ts: [] },
                  internal: { amount: [], ts: [] },
                  external: { amount: [], ts: [] },
                };

                series.forEach((item: { type: string; data: { amount: number; ts: number }[] }) => {
                  if (item.data && item.data.length > 0) {
                    item.data.forEach((dataPoint) => {
                      if (typeMap[item.type]) {
                        typeMap[item.type].amount.push(dataPoint.amount);
                        typeMap[item.type].ts.push(dataPoint.ts);
                      }
                    });
                  }
                });

                return {
                  ...schedule,
                  production: typeMap.production.amount.length > 0 ? typeMap.production : { ts: [], amount: ['N/A'] },
                  consumption: typeMap.consumption.amount.length > 0 ? typeMap.consumption : { ts: [], amount: ['N/A'] },
                  internal: typeMap.internal.amount.length > 0 ? typeMap.internal : { ts: [], amount: ['N/A'] },
                  external: typeMap.external.amount.length > 0 ? typeMap.external : { ts: [], amount: ['N/A'] },
                  status: versionData.status,
                  sender_id: senderId,
                };
              } catch (error) {
                console.error(`Error processing version data for schedule ${schedule.uid}:`, error);
                setError(`Failed to process schedule data for schedule ${schedule.uid}`);

                // Return a fallback object with minimal data
                return {
                  ...schedule,
                  production: { ts: [], amount: ['Error'] },
                  consumption: { ts: [], amount: ['Error'] },
                  internal: { ts: [], amount: ['Error'] },
                  external: { ts: [], amount: ['Error'] },
                  status: 'Error',
                  sender_id: 'Error',
                };
              }
            })
            .catch((error) => {
              console.error(`Error fetching version data for schedule ${schedule.uid}:`, error);
              setError(`Failed to fetch version data for schedule ${schedule.uid}`);

              // Return a fallback object in case of fetch error
              return {
                ...schedule,
                production: { ts: [], amount: ['Schedule Error'] },
                consumption: { ts: [], amount: ['Schedule Error'] },
                internal: { ts: [], amount: ['Schedule Error'] },
                external: { ts: [], amount: ['Schedule Error'] },
                status: 'Schedule Error',
                sender_id: 'Schdule Error',
              };
            });
        });

        Promise.all(schedulesWithTypes)
          .then((updatedSchedules) => {
            setSchedules(updatedSchedules);
          })
          .catch((error) => {
            console.error('Error fetching types and status:', error);
            setError('Error fetching schedules');
          });
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        setError('Error fetching schedules list');
      });
  }, []);

  useEffect(() => {
    const mappedRows = schedules.map((schedule, index) => ({
      id: index,
      uid: schedule.uid,
      market: schedule.market,
      tso: schedule.tso,
      latest_version: schedule.latest_version,
      status: schedule.status || 'Loading...',
      sender_id: schedule.sender_id || 'N/A',
      production: schedule.production,
      consumption: schedule.consumption,
      internal: schedule.internal,
      external: schedule.external,
    }));
    setRows(mappedRows);
  }, [schedules]);

  return (
    <Page>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Debugger
      </Typography>
      <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: 200,
          }}
        >
      <DataGrid
        checkboxSelection
        rows={rows}
        columns={columns}
        loading={rows.length === 0}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        pageSizeOptions={[10, 20, 50]}
        disableColumnResize
        density="compact"
        slotProps={{
        filterPanel: {
          filterFormProps: {
          logicOperatorInputProps: {
            variant: 'outlined',
            size: 'small',
          },
          columnInputProps: {
            variant: 'outlined',
            size: 'small',
            sx: { mt: 'auto' },
          },
          operatorInputProps: {
            variant: 'outlined',
            size: 'small',
            sx: { mt: 'auto' },
          },
          valueInputProps: {
            InputComponentProps: {
            variant: 'outlined',
            size: 'small',
            },
          },
          },
        },
        }}
      />
      </div>
    </Page>
  );
};

export default ScheduleManagementPage;
