import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { LineChart } from '@mui/x-charts/LineChart';
import { styled } from '@mui/system';

import Page from '../components/Page';
import StatusChip from '../components/StatusChip';
import { apiClient } from '../apiClient';
import { version } from 'os';

interface ScheduleDetails {
  uid: string;
  acceptace_level?: string;
  created_ts: string;
  last_modified_ts: string;
  status?: string;
  time_series_uid: string;
  version: number;
  spec: {
    ids: {
      in_area: string;
      out_area: string;
      in_party: string;
      out_party: string;
    };
    type: string;
    data: Array<{ ts: string; amount: number }>;
  };
  versions?: Array<{
    id: string;
    label: string;
    showMark?: boolean;
    curve?: 'catmullRom' | 'linear'| 'monotoneX' | 'monotoneY'|'natural' | 'step' | 'stepBefore' | 'stepAfter';
    data: number[];
    area?: boolean;
    stackOrder?: 'ascending' | 'descending';
  }>;
}

function AreaGradient({ color, id }: { color: string; id: string }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.5} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

const ScheduleDetailsPage = () => {
  const { scheduleId } = useParams<{ scheduleId: string }>();
  const [scheduleDetails, setScheduleDetails] = useState<ScheduleDetails[]>([]);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();

  const colorPalette = [
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.primary.dark,
  ];

  useEffect(() => {
    if (scheduleId) {
      apiClient(`schedule-series/${scheduleId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data: ScheduleDetails[]) => {
          type GroupedSchedules = {
            [key: string]: ScheduleDetails & {
              versions: Array<{
                id: string;
                label: string;
                showMark: boolean;
                curve: string;
                data: number[];
                area: boolean;
                stackOrder: string;
              }>;
              xAxis: string[];
            };
          };

          const groupedSchedules = data.reduce<GroupedSchedules>((acc, schedule, index) => {
            const { time_series_uid } = schedule;

            if (!acc[time_series_uid]) {
              acc[time_series_uid] = {
                ...schedule,
                versions: [],
                xAxis: [],
                spec: {
                  ...schedule.spec,
                  data: [],
                },
              };
            }

            // Extract x and y values
            const xValues = schedule.spec.data.map((point) => point.ts);
            const yValues = schedule.spec.data.map((point) => point.amount);

            // Add x-values if not already set
            if (acc[time_series_uid].xAxis.length === 0) {
              acc[time_series_uid].xAxis = xValues;
            }

            // Add this schedule's version as a chart series
            acc[time_series_uid].versions.push({
              id: `chart-${index}-${schedule.version}`,
              label: `Version ${schedule.version}`,
              showMark: false,
              curve: 'linear',
              data: yValues, // Only y-values
              area: true,
              stackOrder: 'descending',
            });

            // Merge data for use in other parts if needed
            acc[time_series_uid].spec.data.push(...schedule.spec.data);

            return acc;
          }, {});

          // Convert groupedSchedules object to array
          const mergedSchedules = Object.values(groupedSchedules);
          mergedSchedules.forEach((schedule) => {
            // Reverse the versions array for each schedule (didn't work with ascending on the chart)
            schedule.versions.reverse();
          });

          setScheduleDetails(mergedSchedules as ScheduleDetails[]);
        })
        .catch((err) => {
          setError(err.message);
        });
    }
  }, [scheduleId]);

  const colorMap: { [key: string]: string } = {
    production: 'hsl(120, 61%, 50%)', // Green
    consumption: 'hsl(30, 100%, 50%)', // Orange
    internal: 'hsl(200, 70%, 50%)',    // Blue
    external: 'hsl(280, 60%, 50%)',    // Purple
  };

  const SmallTextTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: '0.75rem',
    padding: '2px 20px',
  }));

  return (
    <Page>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Schedule Details for schedule {scheduleId}
      </Typography>
      {error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Grid container spacing={2}>
          {scheduleDetails.map((schedule, index) => {
            const timestamps = schedule.spec.data.map((item) => item.ts);
            const latestVersion = schedule.versions?.[schedule.versions.length - 1];
            const totalAmount = latestVersion?.data.reduce((sum, item) => sum + item, 0) || 0;
            const gradientColor = colorMap[schedule.spec.type];

            return (
              <Grid item xs={12} md={6} key={schedule.uid}>
                <Card variant="outlined">
                  <CardContent>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={2}
                      sx={{ mb: 2 }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Typography 
                          variant="h4" 
                          gutterBottom 
                          sx={{ color: gradientColor }}
                        >
                          {schedule.spec.type.charAt(0).toUpperCase() + schedule.spec.type.slice(1)}
                        </Typography>

                        <Stack 
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          sx={{ mb: 1 }}
                        >
                          <Typography variant="h4" component="p">
                            {totalAmount} MW
                          </Typography>
                          <StatusChip status={schedule.status as 'accepted' | 'submitted' | 'pending' | 'partially_accepted' | 'rejected'} />
                        </Stack>

                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                          Timeseries UID: {schedule.time_series_uid}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          minWidth: '250px',
                          borderRadius: 1,
                          border: '1px solid hsla(220, 20%, 25%, 0.6)',
                          backgroundColor: 'background.paper',
                          overflow: 'hidden',
                        }}
                      >
                        <Table size="small" sx={{ fontSize: '0.75rem' }}>
                          <TableBody>
                            <TableRow>
                              <SmallTextTableCell variant="head">In Area</SmallTextTableCell>
                              <SmallTextTableCell>{schedule.spec.ids.in_area}</SmallTextTableCell>
                            </TableRow>
                            <TableRow>
                              <SmallTextTableCell variant="head">Out Area</SmallTextTableCell>
                              <SmallTextTableCell>{schedule.spec.ids.out_area}</SmallTextTableCell>
                            </TableRow>
                            <TableRow>
                              <SmallTextTableCell variant="head">In Party</SmallTextTableCell>
                              <SmallTextTableCell>{schedule.spec.ids.in_party}</SmallTextTableCell>
                            </TableRow>
                            <TableRow>
                              <SmallTextTableCell variant="head">Out Party</SmallTextTableCell>
                              <SmallTextTableCell>{schedule.spec.ids.out_party}</SmallTextTableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    </Stack>

                    <LineChart
                      colors={(schedule.versions || []).map(
                        (_, versionIndex) => colorMap[schedule.spec.type] || colorPalette[versionIndex % colorPalette.length]
                      )}
                      xAxis={[
                        {
                          scaleType: 'point',
                          data: timestamps,
                          tickInterval: (index, i) => (i + 1) % 5 === 0,
                        },
                      ]}
                      series={schedule.versions || []}
                      height={200}
                      margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
                      grid={{ horizontal: true }}
                      sx={{
                        ...(schedule.versions || []).reduce<Record<string, any>>((styles, version, versionIndex) => {
                          styles[`& .MuiAreaElement-series-${version.id}`] = {
                            fill: `url(#${version.id})`,
                          };
                          styles[`& .MuiLineElement-series-${version.id}`] = {
                            stroke: gradientColor,
                          };
                          return styles;
                        }, {}),
                      }}
                      slotProps={{
                        legend: {
                          hidden: true,
                        },
                      }}
                    >
                      <defs>
                        {(schedule.versions || []).map((version, versionIndex) => (
                          <linearGradient
                            key={version.id}
                            id={version.id}
                            x1="50%"
                            y1="0%"
                            x2="50%"
                            y2="100%"
                          >
                            <stop
                              offset="0%"
                              stopColor={gradientColor}
                              stopOpacity={0.5}
                            />
                            <stop
                              offset="100%"
                              stopColor={gradientColor}
                              stopOpacity={0}
                            />
                          </linearGradient>
                        ))}
                      </defs>
                    </LineChart>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Page>
  );
};

export default ScheduleDetailsPage;
