import React from 'react';
import Page from '../components/Page';

const NotFoundPage: React.FC = () => {
  return (

      <button className="btn btn-error">
        Oops! Page not found!
      </button>
  );
}

export default NotFoundPage;
